
@mixin input-placeholder-color($color) {
  $pseudos: '::-webkit-input-placeholder', // For QQ Browser
  ':-ms-input-placeholder', // For IE
  '::-ms-input-placeholder', // For Edge
  '::placeholder';
  $firefox-pseudos: ':-moz-placeholder', '::-moz-placeholder';

  // It is important to export every pseudo within its own block, because otherwise the placeholder
  // won't be set on the most browsers.
  @each $pseudo in $pseudos {
    &#{$pseudo} {
      //color: unquote($color);
      color: $color;
    }
  }
  // Firefox reduces the opacity of placeholders so we need to keep them opaque to avoid applying
  // double the transparency and causing a11y failures due to text contrast.
  @each $pseudo in $firefox-pseudos {
    &#{$pseudo} {
      color: $color;
      //color: unquote($color);
      opacity: 1;
    }
  }
}

// Mixin to create a primary checkbox.
// Used by the checkbox and select component.
@mixin checkbox-primary($checkedSelector: '.md-checked') {
  .md-ripple {
    color: $primary-600;
  }

  &#{$checkedSelector} .md-ripple {
    color: $background-600;
  }

  .md-ink-ripple {
    color: $foreground-2;
  }

  &#{$checkedSelector} .md-ink-ripple {
    color: $primary-color-087;
  }

  &:not(.md-checked) .md-icon {
    border-color: $foreground-2;
  }

  &#{$checkedSelector} .md-icon {
    background-color: $primary-color-087;
  }

  &#{$checkedSelector}.md-focused .md-container:before {
    background-color: $primary-color-026;
  }

  &#{$checkedSelector} .md-icon:after {
    border-color: $primary-contrast-087;
  }

  & .md-indeterminate[disabled] {
    .md-container {
      color: $foreground-3;
    }
  }
}

// Mixin media query
// Usage: @include rwd(1400)
@mixin rwd($screen) {
  @media (min-width: $screen+'px') {
    @content;
  }
}
