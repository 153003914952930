// Utility classes
// https://github.com/tachyons-css/tachyons
// Functional CSS for humans.
.fdz-theme {

  // Display
  .dn {
    display: none!important;
  }

  .di {
    display: inline!important;
  }

  .db {
    display: block!important;
  }

  .df {
    display: flex!important;
  }

  .dif {
    display: inline-flex!important;
  }

  // Padding
  .pa0 {
    padding: 0!important;
  }

  .pa8 {
    padding: 8px!important;
  }

  .pv0 {
    padding-top: 0!important;
    padding-bottom: 0!important;
  }

  .pt0 {
    padding-top: 0!important;
  }

  .pt8 {
    padding-top: 8px!important;
  }

  .pt16 {
    padding-top: 16px!important;
  }

  .pb0 {
    padding-bottom: 0!important;
  }

  .pb4 {
    padding-bottom: 4px!important;
  }

  .pb8 {
    padding-bottom: 8px !important;
  }

  .pb16 {
    padding-bottom: 16px!important;
  }

  .ph0 {
    padding-left: 0!important;
    padding-right: 0!important;
  }

  .pl0 {
    padding-left: 0!important;
  }

  .pr0 {
    padding-right: 0!important;
  }

  .pr16 {
    padding-right: 16px!important;
  }

  // Margin
  .ma-auto {
    margin: auto;
  }

  .ma0 {
    margin: 0!important;
  }

  .ma8 {
    margin: 8px!important;
  }

  .mh0 {
    margin-left: 0!important;
    margin-right: 0!important;
  }

  .mv0 {
    margin-top: 0!important;
    margin-bottom: 0!important;
  }

  .mv8 {
    margin-top: 8px!important;
    margin-bottom: 8px!important;
  }

  .mv16 {
    margin-top: 16px!important;
    margin-bottom: 16px!important;
  }

  .mt0 {
    margin-top: 0!important;
  }

  .mt4 {
    margin-top: 4px!important;
  }

  .mt8 {
    margin-top: 8px!important;
  }

  .mt16 {
    margin-top: 16px!important;
  }

  .mt18 {
    margin-top: 18px!important;
  }

  .mb0 {
    margin-bottom: 0!important;
  }

  .mb8 {
    margin-bottom: 8px!important;
  }

  .mb12 {
    margin-bottom: 12px!important;
  }

  .mb16 {
    margin-bottom: 16px!important;
  }

  .mb18 {
    margin-bottom: 18px!important;
  }

  .mh0 {
    margin-left: 0!important;
    margin-right: 0!important;
  }

  .mh8 {
    margin-left: 8px!important;
    margin-right: 8px!important;
  }

  .mh16 {
    margin-left: 16px!important;
    margin-right: 16px!important;
  }

  .ml0 {
    margin-left: 0!important;
  }

  .ml6 {
    margin-left: 6px!important;
  }

  .ml9 {
    margin-left: 9px!important;
  }

  .ml16 {
    margin-left: 16px!important;
  }

  .ml28 {
    margin-left: 28px!important;
  }

  .mr8 {
    margin-right: 8px!important;
  }

  .mr10 {
    margin-right: 10px!important;
  }

  .mr16 {
    margin-right: 16px!important;
  }

  .mr20 {
    margin-right: 20px!important;
  }

  .list {
    list-style-type: none!important;
  }

  // Width in %
  .w-10 {
    width: 10%!important;
  }

  .w-20 {
    width: 20%!important;
  }

  .w-25 {
    width: 25%!important;
  }

  .w-30 {
    width: 30%!important;
  }

  .w-33 {
    width: 33%!important;
  }

  .w-34 {
    width: 34%!important;
  }

  .w-40 {
    width: 40%!important;
  }

  .w-50 {
    width: 50%!important;
  }

  .w-60 {
    width: 60%!important;
  }

  .w-70 {
    width: 70%!important;
  }

  .w-75 {
    width: 75%!important;
  }

  .w-80 {
    width: 80%!important;
  }

  .w-90 {
    width: 90%!important;
  }

  .w-100 {
    width: 100%!important;
  }

  // Text transforms
  .ttc {
    text-transform: capitalize!important;
  }

  .ttl {
    text-transform: lowercase!important;
  }

  .ttu {
    text-transform: uppercase!important;
  }

  .ttn {
    text-transform: none!important;
  }

  // Text decoration
  .strike {
    text-decoration: line-through!important;
  }

  .underline {
    text-decoration: underline!important;
  }

  .no-underline {
    text-decoration: none!important;
  }

  // Font Weight
  .normal {
    font-weight: normal!important;
  }

  .b {
    font-weight: bold!important;
  }

  // White Space
  .ws-normal {
    white-space: normal!important;
  }

  .nowrap {
    white-space: nowrap!important;
  }

  .pre {
    white-space: pre!important;
  }

  // Cursor
  .pointer:hover {
    cursor: pointer!important;
  }

  // Overflow
  .overflow-visible {
    overflow: visible!important;
  }

  .overflow-hidden {
    overflow: hidden!important;
  }

  .overflow-scroll {
    overflow: scroll!important;
  }

  .overflow-auto {
    overflow: auto!important;
  }

  .overflow-x-visible {
    overflow-x: visible!important;
  }

  .overflow-x-hidden {
    overflow-x: hidden!important;
  }

  .overflow-x-scroll {
    overflow-x: scroll!important;
  }

  .overflow-x-auto {
    overflow-x: auto!important;
  }

  .overflow-y-visible {
    overflow-y: visible!important;
  }

  .overflow-y-hidden {
    overflow-y: hidden!important;
  }

  .overflow-y-scroll {
    overflow-y: scroll!important;
  }

  .overflow-y-auto {
    overflow-y: auto!important;
  }

  .fg {
    flex-grow: 1;
  }
  .flex-wrap    { flex-wrap: wrap; }
  .items-center { align-items: center; }
  .self-start { align-self: flex-start; }

  // TODO: Check if this is necessary. Copied from style tags
  .ms-flex {
    -ms-flex: auto!important;
  }
}
