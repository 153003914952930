@mixin md-nav-bar-primary {
  > .md-nav-bar {
    background-color: $primary-color;

    .md-button._md-nav-button {
      & {
        color: rgba(0, 0, 0, 0.38);
      }

      &.md-active, &.md-focused {
        color: $primary-contrast;
      }

      &.md-focused {
        background: $primary-contrast-01;
      }
    }
  }
}

@mixin md-nav-bar-warn {
  > .md-nav-bar {
    background-color: $warn-color;

    .md-button._md-nav-button {
      & {
        color: $warn-100;
      }

      &.md-active, &.md-focused {
        color: $warn-contrast;
      }

      &.md-focused {
        background: $warn-contrast-01;
      }
    }
  }
}

@mixin md-nav-bar-accent {
  > .md-nav-bar {
    background-color: $accent-color;

    .md-button._md-nav-button {
      //& {
      //  color: $accent-A100;
      //}

      &.md-active, &.md-focused {
        color: $accent-contrast;
      }

      //&.md-focused {
      //  background: $accent-contrast-01;
      //}
    }

    md-nav-ink-bar {
      color: $primary-600-1;
      background: $primary-600-1;
    }
  }
}

.fdz-theme md-nav-bar {

  .md-nav-bar {
    background-color: transparent;
    border-bottom: none;
  }

  .md-button._md-nav-button {
    &.md-unselected {
      color: $foreground-2;
    }

    &[disabled] {
      color: $foreground-3;
    }
  }

  md-nav-ink-bar {
    color: $accent-color;
    background: $accent-color;
  }

  &.md-accent {
    @include md-nav-bar-accent();
  }

  &.md-warn {
    @include md-nav-bar-warn();
  }

  &.md-primary {
    @include md-nav-bar-primary();
  }
}

.fdz-theme md-toolbar > md-nav-bar {
  @include md-nav-bar-primary();
}

.fdz-theme md-toolbar.md-accent > md-nav-bar {
  @include md-nav-bar-accent();
}

.fdz-theme md-toolbar.md-warn > md-nav-bar {
  @include md-nav-bar-warn();
}

// Menu
.fdz-navbar-menu {
  width: 100%;
  a {
    color: black;
  }
}

.fdz-navbar-menu-accent {
  width: 100%;
}

.fdz-navbar-menu-items {
  padding: 0;
  margin: 0;
  width: 100%;
}

.fdz-navbar-menu-toggle {
  display: block;
  padding: 0 0 0 1em;
  margin: 0 !important;
  width: 100%;
  height: 100%;
  text-align: left;
}

.fdz-navbar-menu-toggle > span {
  display: inline-block;
  width: 85%;
  text-transform: none !important;
  text-align: left !important;
}

.fdz-navbar-button {
  display: block;
  margin: 0 !important;
  padding: 0 0 0 1em;
  width: 100%;
  height: 100%;
  text-align: left !important;
  text-transform: none !important;
}

.fdz-navbar-menu-items .fdz-navbar-button {
  padding: 0 0 0 2em;
}

.fdz-navbar-button-active {
  background-color: #E5F0F7;
}
