
.fdz-theme md-radio-button {
  .md-off {
    border-color: $foreground-2;
  }

  .md-on {
    background-color: $accent-color-087;
  }

  &.md-checked .md-off {
    border-color: $accent-color-087;
  }

  &.md-checked .md-ink-ripple {
    color: $accent-color-087;
  }

  .md-container .md-ripple {
    color: $accent-A700;
  }
}

.fdz-theme md-radio-group,
.fdz-theme md-radio-button {

  &:not([disabled]) {
    .md-primary,
    &.md-primary {
      .md-on {
        background-color: $primary-contrast-087;
      }

      .md-checked,
      &.md-checked {
        .md-off {
          border-color: $primary-contrast-087;
        }
      }

      .md-checked,
      &.md-checked {
        .md-ink-ripple {
          color: $primary-contrast-087;
        }
      }

      .md-container .md-ripple {
        color: $primary-600;
      }
    }


    //.md-warn,
    //&.md-warn {
    //  .md-on {
    //    background-color: $warn-color-087;
    //  }
    //  .md-checked,
    //  &.md-checked {
    //    .md-off {
    //      border-color: $warn-color-087;
    //    }
    //  }
    //  .md-checked,
    //  &.md-checked {
    //    .md-ink-ripple {
    //      color: $warn-color-087;
    //    }
    //  }
    //  .md-container .md-ripple {
    //    color: $warn-600;
    //  }
    //}
  }

  &[disabled] {
    color: $foreground-3;

    .md-container .md-off {
      border-color: $foreground-3;
    }

    .md-container .md-on {
      border-color: $foreground-3;
    }
  }

}

.fdz-theme md-radio-group {
  .md-checked .md-ink-ripple {
    color: $accent-color-026;
  }

  &.md-primary .md-checked:not([disabled]) .md-ink-ripple, .md-checked:not([disabled]).md-primary .md-ink-ripple {
    color: $primary-contrast-026;
  }
}

.fdz-theme md-radio-group.md-focused.ng-empty > md-radio-button:first-child {
  .md-container:before {
    background-color: $foreground-3-026;
  }
}

.fdz-theme md-radio-group {

  color: $primary-contrast-026;

  .md-focused:not(:empty) {
    .md-checked .md-container:before {
      background-color: $accent-color-026;
    }

    &.md-primary .md-checked .md-container:before,
    .md-checked.md-primary .md-container:before {
      background-color: $primary-contrast-026;
    }

    &.md-warn .md-checked .md-container:before,
    .md-checked.md-warn .md-container:before {
      background-color: $warn-color-026;
    }
  }
}
