.fdz-theme md-chips {

  .md-chips {
    box-shadow: 0 1px $foreground-4;

    &.md-focused {
      box-shadow: 0 2px $primary-color;
    }

    .md-chip-input-container {
      input {
        @include input-placeholder-color($foreground-3);
        color: $foreground-1;
      }
    }
  }

  md-chip {
    background: $background-300;
    color: $background-800;

    md-icon {
      color: $background-700;
    }

    &.md-focused {
      background: $primary-color;
      color: $primary-contrast;

      md-icon {
        color: $primary-contrast;
      }
    }

    &._md-chip-editing {
      background: transparent;
      color: $background-800;
    }
  }
  md-chip-remove {
    .md-button {
      md-icon {
        path {
          fill: $background-500;
        }
      }
    }
  }
}

.md-contact-suggestion {
  span.md-contact-email {
    color: $background-400;
  }
}
