.fdz-theme .fdz-data-package {
  box-shadow: none;
  padding-top: 16px;

  md-card-header {
    display: block;
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: rgba(0, 0, 0, 0.12);
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: rgba(0, 0, 0, 0.12);
    margin: 0;
    padding: 8px 0;
    .title {
      font-size: 16px;
      @include rwd(600) {
        font-size: 18px
      }

    }
  }
  md-card-footer {
    padding: 0;
  }
  md-list {
    padding: 0;
  }

  & md-list-item,
  & md-list-item::before {
    min-height: auto;
  }

  md-list-item {
    padding: 0;
    flex-flow: column;
    align-items: flex-start;
    margin-bottom: 8px;
    & md-select {
      margin: 0;
      width: 100%;
      display: block;
    }

    & h5 {
      width: 100%;
    }

    .md-secondary-container {
      width: 100%;
      margin: 0;
      flex: 1 1 auto;
      & > span {
        overflow-wrap: break-word;
        word-wrap: break-word;
      }
      div {
        flex-direction: row;
        display: flex;
        flex: 1 1 auto;
        .md-button {
          margin: -4px 0px -6px 6px;
          height: 32px;
        }
      }
    }
    @include rwd(375) {
      flex-flow: row;
      align-items: center;
      & h5 {
        width: 40%;
      }
      .md-secondary-container {
        width: 190px;
        overflow: hidden;
        word-break: break-all;
      }
    }
    & .md-button.md-raised {
      margin-left: 0;
      margin-top: 16px;
      text-transform: none;
      min-width: 50%;
    }
  }

}
