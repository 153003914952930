.fdz-theme .fdz-package-filter {
  box-shadow: none;
  padding: 0 8px;
  md-card-content {
    padding: 16px 16px 8px 16px;
    label {
      padding-bottom: 8px;
      font-weight: 500;
    }
    md-radio-group {
      color: $brand-primary-text;
      md-radio-button:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.fdz-theme .fdz-search-filter {
  box-shadow: none;
  padding-top: 8px;

  md-card-header {
    display: block;
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: rgba(0, 0, 0, 0.12);
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: rgba(0, 0, 0, 0.12);
    margin: 0;
    padding: 8px 0;
  }

  md-card-content {
    padding: 0;

    & .fdz-button-wrapper {
      // TODO: The same class is in fdz-detail (detail-theme.scss)
      display: flex;
      justify-content: flex-end;
    }

    & .fdz-filter-menu-list {
      padding: 8px 0;

      .md-button {
        margin-left: 0;
        margin-top: 0;
        width: 100%;
        max-width: none;
        text-align: left;
        text-transform: capitalize;
      }
    }

    & .fdz-filter-list {
      padding: 0 8px;

      li md-checkbox {
        margin-bottom: 8px;
        min-height: 25px;

        .md-container {
          position: unset;
          transform: unset;
          box-sizing: border-box;
          display: inline-block;
          width: 20px;
          height: 20px;
          margin: 0px;
        }

        .md-label {
          max-width: 270px;
          white-space: normal;
          vertical-align: top;
          margin-left: 30px;
          margin-top: 0px;
        }
      }
    }
  }
}

// Sidebar
.fdz-search-filter-component md-input-container {
  margin: 0;
}

.fdz-search-filter-component md-input-container input {
  margin-top: 8px;
}

.fdz-search-filter-component {
  margin-bottom: 0 !important;
}

.fdz-search-filter-component button {
  top: 10px !important;
}

.fdz-search-filter-component md-progress-linear {
  bottom: 22px !important;
}

.fdz-search-filter-component md-input-container.md-input-focused label,
.fdz-search-filter-component md-input-container.md-input-has-value label {
  bottom: 75% !important;
}

.fdz-search-filter-component md-input-container label:not(.md-container-ignore) {
  bottom: 88%;
}

.fdz-search-filter-component .ng-active div[ng-message] {
  opacity: 1;
  margin-top: 0;
}

.md-autocomplete-suggestions.fdz-search-filter-items li.md-autocomplete-suggestion {
  border-bottom: 1px solid #ccc;
  height: auto;
  padding: 6px 12px 5px 12px;
  white-space: normal;
}

.fdz-search-filter-items .item-title,
.fdz-search-filter-items .item-metadata {
  display: block;
  line-height: 1.5;
}

.md-autocomplete-suggestions-container.md-not-found .fdz-search-filter-items li {
  margin-left: 0px;
  margin-top: 0px;
  padding: 4px 12px 2px 12px;
}
