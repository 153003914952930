.fdz-theme md-switch {
  .md-ink-ripple {
    color: $background-500;
  }
  .md-thumb {
    background-color: $background-50;
  }
  .md-bar {
    background-color: $background-500;
  }

  &.md-focused {
    &:not(.md-checked) {
      .md-thumb:before {
        background-color: $foreground-4;
      }
    }
    &[disabled] {
      .md-thumb:before {
        background-color: $foreground-4;
      }
    }
  }

  &.md-checked:not([disabled]) {
    .md-ink-ripple {
      color: $accent-color;
    }
    .md-thumb {
      background-color: $accent-color;
    }
    //.md-bar {
    //  background-color: $accent-color-05;
    //}
    //&.md-focused .md-thumb:before {
    //  background-color: $accent-color-026;
    //;
    //}

    &.md-primary {
      .md-ink-ripple {
        color: $primary-color;
      }
      .md-thumb {
        background-color: $primary-color;
      }
      .md-bar {
        background-color: $primary-color-05;
      }
      &.md-focused .md-thumb:before {
        background-color: $primary-color-026;
      }
    }

    &.md-warn {
      .md-ink-ripple {
        color: $warn-color;
      }
      .md-thumb {
        background-color: $warn-color;
      }
      //.md-bar {
      //  background-color: $warn-color-05;
      //}
      //&.md-focused .md-thumb:before {
      //  background-color: $warn-color-026;
      //}
    }
  }

  &[disabled] {
    .md-thumb {
      background-color: $background-400;
    }
    .md-bar {
      background-color: $foreground-4;
    }
  }
}
