/** Theme styles for mdDatepicker. */

.fdz-theme{

  .md-datepicker-input {
    @include input-placeholder-color($foreground-3);
    color: $foreground-1;
  }

  .md-datepicker-input-container {
    border-bottom-color: $foreground-4;

    &.md-datepicker-focused {
      border-bottom-color: $primary-color;

      .md-accent & {
        border-bottom-color: $accent-color;
      }

      .md-warn & {
        border-bottom-color: $warn-700;
      }
    }

    &.md-datepicker-invalid {
      border-bottom-color: $warn-700;
    }
  }

  .md-datepicker-calendar-pane {
    border-color: $background-hue-1;
  }

  .md-datepicker-triangle-button {
    .md-datepicker-expand-triangle {
      border-top-color: $foreground-2;
    }
  }

  // Open state for all of the elements of the picker.
  .md-datepicker-open {
    .md-datepicker-calendar-icon {
      color: $primary-color;
    }

    &.md-accent, .md-accent & {
      .md-datepicker-calendar-icon {
        color: $accent-color;
      }
    }

    &.md-warn, .md-warn & {
      .md-datepicker-calendar-icon {
        color: $warn-700;
      }
    }
  }

  .md-datepicker-calendar {
    background: $background-hue-1;
  }

  $mask-color: $background-hue-1;

  // The box-shadow acts as the background for the overlay.
  .md-datepicker-input-mask-opaque {
    box-shadow: 0 0 0 9999px $mask-color;
  }

  .md-datepicker-open .md-datepicker-input-container {
    background: $mask-color;
  }
}
