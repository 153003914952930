.fdz-theme md-backdrop {
  &.md-sidenav-backdrop {
    z-index: 19;
  }
  background-color: $background-900-00;

  &.md-opaque {
    background-color: $brand-background-500-032;
  }

}
