.fdz-theme .md-subheader {
  color: $foreground-2-023;
  background-color: $background-default;

  &.md-primary {
    color: $primary-color;
  }
  &.md-accent {
    color: $accent-color;
  }
  &.md-warn {
    color: $warn-color;
  }
}
