/** Theme styles for mdCalendar. */
.fdz-theme {
  .md-calendar {
    background: $background-hue-1;
    color: $foreground-1-087;

    tr:last-child td {
      border-bottom-color: $background-hue-2;
    }
  }

  .md-calendar-day-header {
    background: $background-500-032;
    color: $foreground-1-087;
  }

  .md-calendar-date.md-calendar-date-today {

    .md-calendar-date-selection-indicator {
      border: 1px solid $primary-500; // blue-500
    }

    &.md-calendar-date-disabled {
      color: $primary-500-06;
    }
  }

  .md-calendar-date-selection-indicator {
    .md-calendar-date.md-focus &,
    &:hover {
      background: $background-500-032;
    }
  }

  // Selected style goes after hover and focus so that it takes priority.
  .md-calendar-date.md-calendar-selected-date,
  .md-calendar-date.md-focus.md-calendar-selected-date {
    .md-calendar-date-selection-indicator {
      background: $primary-500; // blue-500
      color: $primary-500-contrast; // white
      border-color: transparent;
    }
  }

  .md-calendar-date-disabled,
  .md-calendar-month-label-disabled {
    color: $foreground-3;
  }

  .md-calendar-month-label md-icon {
    color: $foreground-1;
  }
}
