[data-ng-cloak].fdz-splash {
  display: table !important;
}

.fdz-splash {
  display: none;
  width: 100%;
  height: 100vh;
  background-color: white;
  color: black;
}

.fdz-splash .fdz-app-title {
  padding: 8px;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  font-size: 20px;
}

.fdz-splash img {
  display: block;
  margin: 16px auto;
  max-width: 500px;
}

.fdz-splash progress {
  height: 1em;
  width: 10em;
}
