.fdz-theme md-progress-linear {
  .md-container {
    background-color: $primary-100;
  }

  .md-bar {
    background-color: $primary-contrast;
  }

  &.md-warn {
    .md-container {
      background-color: $warn-100;
    }

    .md-bar {
      background-color: $warn-color;
    }
  }

  &.md-accent {
    //.md-container {
    //  background-color: $accent-100;
    //}

    .md-bar {
      background-color: $accent-color;
    }
  }

  &[md-mode=buffer] {
    &.md-primary {
	  .md-bar1 {
        background-color: $primary-100;
      }
      .md-dashed:before {
        background: radial-gradient($primary-100 0%, $primary-100 16%, transparent 42%);
      }
	}
    &.md-warn {
      .md-bar1 {
        background-color: $warn-100;
      }
      .md-dashed:before {
        background: radial-gradient($warn-100 0%, $warn-100 16%, transparent 42%);
      }
    }
    //&.md-accent {
    //  //.md-bar1 {
    //  //  background-color: $accent-100;
    //  //}
    //  .md-dashed:before {
    //    background: radial-gradient($accent-100 0%, $accent-100 16%, transparent 42%);
    //  }
    //}
  }
}
