geographic-coverage-list {
  display: block;

  md-card-content {
    padding: 0;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }
}

geographic-coverage {
  display: block;
}
