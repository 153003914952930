$card-border-radius: 2px !default;

.fdz-theme .fdz-search {
  position: relative;
  border: 1px rgba(0, 0, 0, 0.12) solid;
  border-radius: $card-border-radius;
  margin: 24px 8px 0px 8px;
  padding: 4px;
  @include rwd(600) {
    margin: 24px 16px 0px 16px;
  }
  @include rwd(959) {
    margin: 24px 24px 0 24px;
  }
  & md-input-container.fdz-search__input {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    & md-icon {
      display: flex;
      position: relative;
      margin: 0 6px 0 6px;
      top: 0px;
      left: 0px;
      right: unset;
    }
    & .md-input {
      flex-grow: 2;
      margin: 0;
      padding: 0;
      border: 0;
      order: unset;
      left: unset;
      width: inherit;
      line-height: 16px;
      height: 24px;
      -ms-flex-order: 0;
    }

    & md-input-focused .md-input {
      border: 0;
      outline: none;
    }

    & .md-button {
      flex-grow: 1;
      margin: 0;
      min-height: 24px;
      min-width: auto;
      max-width: 80px;
      border-radius: 0;
    }
  }

  & md-progress-linear {
    display: block;
    position: absolute;
    width: 100%;
    height: 5px;
    padding-top: 0 !important;
    margin-bottom: 0 !important;
    left: 0;
    top: 27px;
  }
}

// Search tabs (Auth. User)
md-tab-item:hover {
  background-color: rgba(158, 158, 158, 0.2) !important;
}

.md-select-placeholder {
  font-weight: bold;
}

.md-select-menu-ie11-fix {
  max-height: 15em;
}

.fdz-select-header {
  background-color: white;
  padding-left: 10px;
}

.fdz-select-header md-input-container {
  margin-bottom: 10px;
  margin-top: 10px;
}

.fdz-select-header md-input-container.md-input-focused label,
.fdz-select-header md-input-container.md-input-has-value label {
  bottom: 65% !important;
}

.fdz-select-header .md-errors-spacer {
  display: none;
}

.fdz-filter-select {
  margin-left: 8px;
  margin-right: 0;
  margin-bottom: 24px;
}

md-input-container.fdz-filter-select.md-input-focused label,
md-input-container.fdz-filter-select.md-input-has-value label {
  bottom: 89% !important;
}

input::-ms-clear {
  display: none;
}
.fdz-search-input {
  display: block;
  margin-left: 8px;
  margin-right: 16px;
  margin-bottom: 24px;
}

.fdz-search-input .md-errors-spacer {
  display: none;
}

md-input-container label {
  margin: 0px;
}

.fdz-filter-badge {
  display: inline;
  background-color: $accent-color;
  padding: 0.2em 0.6em;
  line-height: 1;
  color: black;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 1em;
}
