md-input-container {

  // The asterisk of the select should always use the warn color.
  .fdz-theme md-select .md-select-value {
    span:first-child:after {
      color: $primary-contrast;
    }
  }

  // When the select is blurred and not invalid then the asterisk should use the foreground color.
  &:not(.md-input-focused):not(.md-input-invalid) {
    .fdz-theme  md-select .md-select-value {
      span:first-child:after {
        color: $foreground-3-038;
      }
    }
  }

  &.md-input-focused:not(.md-input-has-value) {
    .fdz-theme md-select .md-select-value {
      color: $accent-color;

      &.md-select-placeholder {
        color: $accent-color;
      }
    }
  }

  &.md-input-invalid {
    .fdz-theme md-select .md-select-value {
      color: $warn-700 !important;
      border-bottom-color: $warn-700 !important;
    }

    .fdz-theme md-select.md-no-underline .md-select-value {
      border-bottom-color: transparent !important;
    }
  }

  &:not(.md-input-invalid) {
    &.md-input-focused {
      &.md-accent {
        .md-select-value {
          border-color: $accent-color;
          span {
            color: $accent-color;
          }
        }
      }
      &.md-warn {
        .md-select-value {
          border-color: $warn-700;
          span {
            color: $warn-700;
          }
        }
      }
    }
  }

}

.fdz-theme md-select {
  &[disabled] .md-select-value {
    border-bottom-color: transparent;
    background-image: linear-gradient(to right, $foreground-3 0%, $foreground-3 33%, transparent 0%);
    background-image: -ms-linear-gradient(left, transparent 0%, $foreground-3 100%);
  }

  .md-select-value {
    border-bottom-color: $foreground-4;

    &.md-select-placeholder {
      color: $foreground-3;
    }

    span:first-child:after {
      color: $warn-700
    }
  }

  &.md-no-underline .md-select-value {
    border-bottom-color: transparent !important;
  }

  &.ng-invalid.ng-touched {
    .md-select-value {
      color: $warn-700 !important;
      border-bottom-color: $warn-700 !important;
    }

    &.md-no-underline .md-select-value {
      border-bottom-color: transparent !important;
    }
  }

  &:not([disabled]):focus {
    .md-select-value {
      border-bottom-color: $primary-contrast;
      color: $foreground-1;
      &.md-select-placeholder {
        color: $foreground-1;
      }
    }

    &.md-no-underline .md-select-value {
      border-bottom-color: transparent !important;
    }

    &.md-accent .md-select-value {
      border-bottom-color: $accent-color;
    }

    &.md-warn .md-select-value {
      border-bottom-color: $warn-color;
    }
  }

  &[disabled] {
    .md-select-value {
      color: $foreground-3;

      &.md-select-placeholder {
        color: $foreground-3;
      }
    }

    .md-select-icon {
      color: $foreground-3;
    }
  }

  .md-select-icon {
    color: $foreground-2;
  }
}

.fdz-theme md-select-menu {
  md-content {
    background-color: $background-hue-1;

    md-optgroup {
      color: $foreground-2;
    }

    md-option {
      color: $foreground-1;

      &[disabled] {
        .md-text {
          color: $foreground-3;
        }
      }

      &:not([disabled]) {
        &:focus,
        &:hover,
        &.md-focused {
          background-color: $background-500-018;
        }
      }

      &[selected] {
        color: $primary-500;
        &:focus {
          color: $primary-600;
        }
        &.md-accent {
          color: $accent-color;
          &:focus {
            color: $accent-A700;
          }
        }
      }
    }
  }
}

.fdz-theme .md-checkbox-enabled {
  @include checkbox-primary('[selected]');

  md-option .md-text {
    color: $foreground-1;
  }
}
