.fdz-fab-button-container {
  position: fixed;
  bottom: 1em;
  right: 1em;
  z-index: 15;
}

md-dialog .fdz-fab-button-container {
  position: absolute;
}
