@mixin md-autocomplete-input($input-color) {
  md-input-container.md-input-focused {
    .md-input {
      border-color: $input-color;
    }
    label,
    md-icon {
      color: $input-color;
    }
  }
}
@mixin md-autocomplete-progress($container-color, $bar-color) {
  md-progress-linear {
    .md-container {
      background-color: $container-color;
    }
    .md-bar {
      background-color: $bar-color;
    }
  }
}
.fdz-theme md-autocomplete {
  background: $primary-color;
  &[disabled]:not([md-floating-label]) {
    background: $background-300;
  }
  button {
    md-icon {
      path {
        fill: $background-600;
      }
    }
    &:after {
      background: $background-600-03;
    }
  }
  input {
    color: $foreground-1;
  }
  &.md-accent {
    @include md-autocomplete-input($accent-color);
    @include md-autocomplete-progress($accent-100, $accent-color);
  }
  &.md-warn {
    @include md-autocomplete-input($warn-700);
    @include md-autocomplete-progress($warn-100, $warn-color);
  }
}
.fdz-theme .md-autocomplete-suggestions-container,
.fdz-theme .md-autocomplete-standard-list-container {
  background: $background-hue-1;
  .md-autocomplete-suggestion {
    color: $foreground-1;
    &:hover,
    &.selected {
      background: $background-500-018;
    }
  }
}
