@mixin md-tab-primary {
  > md-tabs-wrapper {
    background-color: $primary-color;
    > md-tabs-canvas {
      > md-pagination-wrapper {
        > md-tab-item:not([disabled]) {
          &, md-icon {
            color: $primary-100;
          }
          &.md-active, &.md-focused {
            &, md-icon {
              color: $primary-contrast;
            }
          }
          &.md-focused {
            background: $primary-contrast-01;
          }
        }
      }
    }
  }
}
@mixin md-tab-warn {
  > md-tabs-wrapper {
    background-color: $warn-color;
    > md-tabs-canvas {
      > md-pagination-wrapper {
        > md-tab-item:not([disabled]) {
          &, md-icon {
            color: $warn-100;
          }
          &.md-active, &.md-focused {
            &, md-icon {
              color: $warn-contrast;
            }
          }
          &.md-focused {
            background: $warn-contrast-01;
          }
        }
      }
    }
  }
}
@mixin md-tab-accent {
  > md-tabs-wrapper {
    background-color: $accent-color;
    > md-tabs-canvas {
      > md-pagination-wrapper {
        > md-tab-item:not([disabled]) {
          //&, md-icon {
          //  color: $accent-A100;
          //}
          &.md-active, &.md-focused {
            &, md-icon {
              color: $accent-contrast;
            }
          }
          //&.md-focused {
          //  background: $accent-contrast-01;
          //}
        }
        > md-ink-bar {
          color: $primary-600-1;
          background: $primary-600-1;
        }
      }
    }
  }

}
.fdz-theme md-tabs {
  md-tabs-wrapper {
    background-color: transparent;
    border-color: $foreground-4;
  }
  .md-paginator md-icon {
    color: $primary-contrast;
  }

  md-ink-bar {
    color: $accent-color;
    background: $accent-color;
  }

  .md-tab {
    color: $foreground-3;
    text-transform: none;
    &[disabled] {
      &, md-icon {
        color: $foreground-3;
        opacity: 0.4;
      }
    }
    &.md-active, &.md-focused {
      &, md-icon {
        color: $foreground-1;
        //color: $brand-secondary;
      }
    }
    &.md-focused {
      background: $primary-color-01;
    }
    .md-ripple-container {
      color: $accent-color;
    }
  }

  &.md-accent {
    @include md-tab-accent();
  }

  &.md-primary {
    @include md-tab-primary();
  }

  &.md-warn {
    @include md-tab-warn();
  }
}

.fdz-theme md-toolbar > md-tabs {
  @include md-tab-primary();
}
.fdz-theme md-toolbar.md-accent > md-tabs {
  @include md-tab-accent();
}
.fdz-theme md-toolbar.md-warn > md-tabs {
  @include md-tab-warn();
}
