// Variables

$content-width: 1000px;

// revert checkbox size to 20px (was changed to 18px in angular-material 1.2)
$checkbox-width: 20px !default;
$checkbox-height: $checkbox-width !default;

// Brand color palettes RGBA
$brand-primary: rgba(255, 255, 255, 0.87);
$brand-primary-026: rgba(255, 255, 255, 0.26);
$brand-primary-05: rgba(255, 255, 255, 0.5);
$brand-primary-087: rgba(255, 255, 255, 0.87);
$brand-primary-50: rgba(255, 255, 255, 1); // Default
$brand-primary-100: rgba(250, 250, 250, 1);
$brand-primary-200: rgba(245, 245, 245, 1);
$brand-primary-200-038: rgba(245, 245, 245, 0.38);
$brand-primary-300: rgba(240, 240, 240, 1);
$brand-primary-400: rgba(222, 222, 222, 1);
$brand-primary-500: rgba(194, 194, 194, 1);
$brand-primary-500-contrast: $brand-primary;
$brand-primary-600: rgba(151, 151, 151, 1);
$brand-primary-700: rgba(129, 129, 129, 1);
$brand-primary-800: rgba(96, 96, 96, 1);
$brand-primary-900: rgba(60, 60, 60, 1);

$brand-secondary: rgba(0, 113, 188, 1);
$brand-secondary-01: rgba(0, 113, 188, 0.1);
$brand-secondary-026: rgba(0, 113, 188, 0.26);
$brand-secondary-087: rgba(0, 113, 188, 0.87);
$brand-secondary-01: rgba(0, 113, 188, 0.1);
$brand-secondary-50: rgba(224, 244, 254, 1);
$brand-secondary-100: rgba(178, 227, 253, 1);
$brand-secondary-200: rgba(126, 208, 251, 1);
$brand-secondary-200-038: rgba(126, 208, 251, 0.38);
$brand-secondary-300: rgba(74, 190, 247, 1);
$brand-secondary-400: rgba(32, 176, 246, 1);
$brand-secondary-500: rgba(0, 162, 243, 1);
$brand-secondary-500-06: rgba(0, 162, 243, 0.6);
$brand-secondary-500-contrast: $brand-primary;
$brand-secondary-600: rgba(0, 149, 228, 1);
$brand-secondary-700: rgba(0, 130, 208, 1);
$brand-secondary-800: rgba(0, 113, 188, 1); // Default
$brand-secondary-900: rgba(3, 81, 154, 1);

$brand-third-50: rgba(255, 253, 232, 1);
$brand-third-100: rgba(255, 248, 199, 1);
$brand-third-200: rgba(255, 244, 162, 1);
$brand-third-300: rgba(255, 239, 126, 1);
$brand-third-400: rgba(255, 234, 98, 1);
$brand-third-500: rgba(253, 229, 72, 1);
$brand-third-600: rgba(254, 214, 71, 1);
$brand-third-700: rgba(251, 191, 64, 1);
$brand-third-800: rgba(247, 168, 57, 1); // Default
$brand-third-900: rgba(240, 129, 45, 1);

$brand-background-50:  rgba(255, 255, 255, 1); // Default
$brand-background-100: rgba(250, 250, 250, 1);
$brand-background-200: rgba(245, 245, 245, 1);
$brand-background-300: rgba(240, 240, 240, 1);
$brand-background-400: rgba(222, 222, 222, 1);
$brand-background-500: rgba(194, 194, 194, 1);
$brand-background-500-018: rgba(194, 194, 194, 0.18);
$brand-background-500-02: rgba(194, 194, 194, 0.2);
$brand-background-500-032: rgba(194, 194, 194, 0.32);
$brand-background-600: rgba(151, 151, 151, 1);
$brand-background-600-032: rgba(151, 151, 151, 0.32);
$brand-background-700: rgba(129, 129, 129, 1);
$brand-background-800: rgba(96, 96, 96, 1);
$brand-background-900: rgba(60, 60, 60, 1);
$brand-background-900-00: rgba(60, 60, 60, 0);
$brand-background-900-01: rgba(60, 60, 60, 0.1);

$brand-primary: $brand-primary-50;
$brand-primary-light: #fff;
$brand-primary-dark: #ccc;
$brand-primary-text: #000;
$brand-secondary: $brand-secondary-800;
$brand-secondary-light:#589fef;
$brand-secondary-dark:#00468b;
$brand-secondary-text:#fff;
$brand-third: $brand-third-800;

$brand-bg: #E6E6E6;
$brand-warning: #F69F24;
//$brand-error: #C91630;
$brand-error: rgb(217, 24, 53);

// Colors vars in templates
$primary-color: $brand-primary;
$primary-color-01: $brand-primary;
$primary-color-026: $brand-primary-026;
$primary-color-05: $brand-primary-05;
$primary-color-087: $brand-primary-087;
$primary-contrast: $brand-secondary;
$primary-contrast-01: $brand-secondary-01;
$primary-contrast-026: $brand-secondary;
$primary-contrast-087: $brand-secondary;
$primary-100: $brand-secondary-50;
$primary-200-038: $brand-secondary-200-038;
$primary-500: $brand-secondary;
$primary-500-06: $brand-secondary-500;
$primary-500-contrast: $brand-secondary-500-contrast;
$primary-600-1: $brand-secondary-600;
$primary-600: $brand-secondary-600;
$primary-700: $brand-secondary-700;


$background-default: $brand-bg;
$background-contrast: #08ff00;
$background-50: $brand-background-50;
$background-100: $brand-background-100;
$background-200: $brand-background-200;
$background-300: $brand-background-300;
$background-400: $brand-background-400;
$background-500: $brand-background-500;
$background-500-018: $brand-background-500-018;
$background-500-02: $brand-background-500-02;
$background-500-032: $brand-background-500-032;
$background-600: $brand-background-600;
$background-600-03: $brand-background-600-032;
$background-700: $brand-background-700;
$background-700-contrast: $brand-background-50;
$background-800: $brand-background-800;
$background-900: $brand-background-900;
$background-900-00: $brand-background-900-00;
$background-900-10: $brand-background-900-01;

$background-hue-1: $brand-primary-50;
$background-hue-2: rgba(5, 153, 0, 0.93);
$background-hue-3: rgba(5, 103, 0, 0.93);

$warn-color: $brand-third;
$warn-color-026: #fb0cff;
//$warn-color-05: #fb0cff;
//$warn-color-038: #fb0cff;
$warn-color-087: #fb0cff;
$warn-contrast: $brand-primary-text;
$warn-contrast-01: $brand-primary-text;
$warn-100: #F69F24;
$warn-600: #F69F24;
$warn-700: $brand-error;

$foreground-1: rgba(0,0,0,1);
$foreground-1-087: rgba(0,0,0,0.87);
$foreground-2: rgba(0,0,0,0.54);
$foreground-2-023: rgba(0,0,0,0.23);
$foreground-3: rgba(0,0,0,0.38);
$foreground-3-026: rgba(0,0,0,0.26);
$foreground-3-038: rgba(0,0,0,0.38);
$foreground-4: rgba(0,0,0,0.12);

$accent-color: $brand-third;
//$accent-color-05: #08fff2;
$accent-color-026: #08fff2;
$accent-color-087: #08fff2;
$accent-100: #08fff2;
$accent-contrast: $brand-primary-text;
//$accent-contrast-01: #08fff2;
//$accent-contrast-087: #08fff2;
//$accent-A100: #08fff2;
//$accent-A200-02: #08fff2;
$accent-A700: $brand-third-900;
