.fdz-theme md-slider {

  .md-track {
    background-color: $foreground-3;
  }
  .md-track-ticks {
    color: $background-contrast;
  }
  //.md-focus-ring {
  //  background-color: $accent-A200-02;
  //}
  .md-disabled-thumb {
    border-color: $background-default;
    background-color: $background-default;
  }

  &.md-min {
    .md-thumb:after {
      background-color: $background-default;
      border-color: $foreground-3;
    }

    .md-focus-ring {
      background-color: $foreground-3-038;
    }

    &[md-discrete] {
      .md-thumb {
        &:after {
          background-color: $background-contrast;
          border-color: transparent;
        }
      }

      .md-sign {
        background-color: $background-400;
        &:after {
          border-top-color: $background-400;
        }
      }

      &[md-vertical] {
        .md-sign:after {
          border-top-color: transparent;
          border-left-color: $background-400;
        }
      }
    }
  }

  .md-track.md-track-fill {
    background-color: $accent-color;
  }
  .md-thumb:after {
    border-color: $accent-color;
    background-color: $accent-color;
  }
  .md-sign {
    background-color: $accent-color;
    &:after {
      border-top-color: $accent-color;
    }
  }

  &[md-vertical] {
    .md-sign:after {
      border-top-color: transparent;
      border-left-color: $accent-color;
    }
  }

  .md-thumb-text {
    color: $accent-contrast;
  }

  &.md-warn {
  //  .md-focus-ring {
  //    background-color: $warn-color-038;
  //  }
  //  .md-track.md-track-fill {
  //    background-color: $warn-color;
  //  }
  //  .md-thumb:after {
  //    border-color: $warn-color;
  //    background-color: $warn-color;
  //  }
  //  .md-sign {
  //    background-color: $warn-color;
  //
  //    &:after {
  //      border-top-color: $warn-color;
  //    }
  //  }

    &[md-vertical] {
      .md-sign:after {
        border-top-color: transparent;
        border-left-color: $warn-color;
      }
    }

    .md-thumb-text {
      color: $warn-contrast$warn-contrast;
    }
  }

  &.md-primary {
    .md-focus-ring {
      background-color: $primary-200-038;
    }
    .md-track.md-track-fill {
      background-color: $primary-color;
    }
    .md-thumb:after {
      border-color: $primary-color;
      background-color: $primary-color;
    }
    .md-sign {
      background-color: $primary-color;

      &:after {
        border-top-color: $primary-color;
      }
    }

    &[md-vertical] {
      .md-sign:after {
        border-top-color: transparent;
        border-left-color: $primary-color;
      }
    }

    .md-thumb-text {
      color: $primary-contrast;
    }
  }

  &[disabled] {
    .md-thumb:after {
      border-color: transparent;
    }
    &:not(.md-min), &[md-discrete] {
      .md-thumb:after {
        background-color: $foreground-3;
        border-color: transparent;
      }
    }
  }

  &[disabled][readonly] {
    .md-sign {
      background-color: $background-400;
      &:after {
        border-top-color: $background-400;
      }
    }

    &[md-vertical] {
      .md-sign:after {
        border-top-color: transparent;
        border-left-color: $background-400;
      }
    }

    .md-disabled-thumb {
      border-color: transparent;
      background-color: transparent;
    }
  }
}

md-slider-container {
  &[disabled] {
    & > *:first-child,
    & > *:last-child {
      &:not(md-slider){
        color: $foreground-3;
      }
    }
  }
}
