.fdz-theme md-toast {

  .md-toast-content {
    background-color: #323232;
    color: $background-50;

    .md-button {
      color: $background-50;

      &.md-highlight {
        // By default the toast should use the accent color as the primary highlight color
        color: $accent-color;

        &.md-primary {
          color: $primary-color;
        }

        &.md-warn {
          color: $warn-color;
        }
      }
    }
  }

  &.md-accent > .md-toast-content {
    font-weight: bold;
    border: 1px solid black;
    background-color: $warn-100;
    color: black !important;
  }
}

.fdz-theme .toast-wrapper {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 2000;
  padding-top: 64px;
}
.fdz-theme #toast-container > md-toast {
  z-index: 2000;
  pointer-events: all;
}
